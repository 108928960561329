import { Button } from '@synoptic/ui-kit/button/button.tsx';
import {
  DropdownMenuContent,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from '@synoptic/ui-kit/dropdown-menu/dropdown-menu.js';
import { DropdownMenuItem } from '@synoptic/ui-kit/dropdown-menu/dropdown-menu.tsx';
import { MenuIcon } from '@synoptic/ui-kit/icons/react/menu.tsx';
import { useIsTablet } from '@synoptic/ui-kit/responsive/hooks.ts';
import { FC } from 'react';
import {
  menuItemButton,
  menuItemText,
  moreMenuDropdownItem,
} from './menu-action/menu-action.css.ts';
import { MenuAction } from './menu-actions.tsx';
import { moreMenuDropdown } from './menu.css.ts';

export const MoreMenu: FC<{
  mobileMenuActions: MenuAction[];
  tabletMenuActions: MenuAction[];
}> = ({ mobileMenuActions, tabletMenuActions }) => {
  const isTablet = useIsTablet();
  const menuItems = isTablet ? tabletMenuActions : mobileMenuActions;

  return menuItems.length ? (
    <DropdownMenuRoot>
      <DropdownMenuTrigger>
        <Button
          className={menuItemButton}
          startIcon={<MenuIcon />}
          variant="ghost"
        >
          <span className={menuItemText}>More</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className={moreMenuDropdown}>
        {menuItems.map((item, index) => (
          <DropdownMenuItem
            key={index}
            className={moreMenuDropdownItem}
            asChild
          >
            {item}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenuRoot>
  ) : null;
};
