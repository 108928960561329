import { NavLink } from '@/routes-utils/navigation.tsx';
import { useReadQuery } from '@apollo/client';
import { FC, ReactNode, Suspense } from 'react';
import { useAppLoader } from '../../../loader.ts';
import * as menuActionStyles from './menu-action.css.ts';
import {
  menuItemBadge,
  menuItemLink,
  menuItemText,
} from './menu-action.css.ts';

const NotificationBadge = () => {
  const { unreadNotificationsQueryRef } = useAppLoader();
  const {
    data: {
      notifications: { items: notificationItems },
    },
  } = useReadQuery(unreadNotificationsQueryRef);
  const notificationsCount =
    notificationItems.length !== 0
      ? notificationItems.length < 10
        ? String(notificationItems.length)
        : '9+'
      : undefined;

  return notificationsCount ? (
    <span data-testid={'menu-notifications-badge'} className={menuItemBadge}>
      {notificationsCount}
    </span>
  ) : null;
};

export const NotificationsMenuAction: FC<{
  icon: ReactNode;
  label: string;
  to: string;
}> = ({ icon, label, to }) => {
  return (
    <NavLink to={to} className={menuItemLink} prefetch="render">
      <div className={menuActionStyles.menuItemIcon}>
        {icon}

        <Suspense fallback={null}>
          <NotificationBadge />
        </Suspense>
      </div>
      <span className={menuItemText}>{label}</span>
    </NavLink>
  );
};
