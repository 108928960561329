import { paths } from '@/routes-utils/paths.ts';
import { BellIcon } from '@synoptic/ui-kit/icons/react/bell.tsx';
import { SearchIcon } from '@synoptic/ui-kit/icons/react/search.tsx';
import { ShareAndroidIcon } from '@synoptic/ui-kit/icons/react/share-android.tsx';
import { TrophyIcon } from '@synoptic/ui-kit/icons/react/trophy.tsx';
import { ViewColumns3Icon } from '@synoptic/ui-kit/icons/react/view-columns-3.tsx';
import { Fragment } from 'react';
import { CreatePostMenuAction } from './menu-action/create-post-menu-action.tsx';
import { HomeMenuAction } from './menu-action/home-menu-action.tsx';
import { MenuActionLink } from './menu-action/menu-action-link.tsx';
import { NotificationsMenuAction } from './menu-action/notifications-menu-action.tsx';
import { ReportMenuAction } from './menu-action/report-menu-action.tsx';
import { StreamsMenuAction } from './menu-action/streams-menu-action.tsx';
import { MoreMenu } from './more-menu.tsx';
import { GiftIcon } from '@synoptic/ui-kit/icons/react/gift.js';

export const menuActions = {
  Home: <HomeMenuAction />,
  Tournaments: (
    <MenuActionLink
      icon={<TrophyIcon />}
      to={paths.tournaments}
      label={'Tournaments'}
    />
  ),
  Streams: (
    <StreamsMenuAction
      icon={<ShareAndroidIcon />}
      to={paths.streams}
      label={'Data streams'}
    />
  ),
  Notifications: (
    <NotificationsMenuAction
      icon={<BellIcon />}
      to={paths.notifications}
      label={'Notifications'}
    />
  ),
  Invites: (
    <MenuActionLink
      icon={<GiftIcon />}
      label="Invite friends"
      to={paths.inviteFriend}
    />
  ),

  CreatePost: <CreatePostMenuAction />,
  Search: (
    <MenuActionLink icon={<SearchIcon />} label={'Search'} to={paths.search} />
  ),
  More: <Fragment />,
  Report: <ReportMenuAction />,

  StreamDeck: (
    <MenuActionLink
      icon={<ViewColumns3Icon />}
      to={paths.streamDeck}
      label={'StreamDeck'}
    />
  ),
};
menuActions.More = (
  <MoreMenu
    mobileMenuActions={[
      menuActions.Tournaments,
      menuActions.Streams,
      menuActions.Report,
    ]}
    tabletMenuActions={[]}
  />
);

export type MenuAction = (typeof menuActions)[keyof typeof menuActions];
