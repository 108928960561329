import { Link } from '@/routes-utils/navigation.tsx';
import { paths } from '@/routes-utils/paths.ts';
import { SynopticLogoIcon } from '@synoptic/ui-kit/icons/react/synoptic-logo.tsx';
import { SynopticLogoCompactIcon } from '@synoptic/ui-kit/icons/react/synoptic-logo-compact.tsx';
import { useIsLaptop } from '@synoptic/ui-kit/responsive/hooks.ts';
import {
  betaTag,
  headerLogoContent,
  logo,
  logoCompact,
  logoLinkWrapper,
  logoWrapperCollapsed,
} from './header-logo.css.ts';
import { useSidebarContext } from './sidebar-context.tsx';
import clsx from 'clsx';

export const HeaderLogo = () => {
  const isLaptop = useIsLaptop();

  const { isCollapsed } = useSidebarContext();

  const isCompact = !isLaptop || isCollapsed;

  return (
    <Link
      to={paths.default}
      className={clsx(logoLinkWrapper, {
        [logoWrapperCollapsed]: isCollapsed,
      })}
    >
      <div className={headerLogoContent}>
        {isCompact ? (
          <SynopticLogoCompactIcon
            className={logoCompact}
            height={29}
            width={21}
          />
        ) : (
          <>
            <SynopticLogoIcon className={logo} width={107} height={29} />
            <div className={betaTag}>Beta</div>
          </>
        )}
        {/*TODO: Replace <span>S</span> when it will be ready in Figma (now there is bad quality)*/}
      </div>
    </Link>
  );
};
