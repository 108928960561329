import PullToRefresh from 'pulltorefreshjs';
import { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { ArrowDownIcon } from '@synoptic/ui-kit/icons/react/arrow-down.tsx';
import { RefreshIcon } from '@synoptic/ui-kit/icons/react/refresh.tsx';

export const usePullToRefreshIos = () => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isInWebAppiOS = window.navigator.standalone === true;
    if (isInWebAppiOS) {
      PullToRefresh.init({
        mainElement: 'body',
        onRefresh() {
          window.location.reload();
        },
        iconArrow: renderToString(<ArrowDownIcon />),
        iconRefreshing: renderToString(<RefreshIcon />),
      });

      return () => {
        PullToRefresh.destroyAll();
      };
    }
  }, []);
};
