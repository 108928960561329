import { paths } from '@/routes-utils/paths';
import { useMatch } from 'react-router';
import { HomeIcon } from '@synoptic/ui-kit/icons/react/home.js';
import { MenuActionLink } from './menu-action-link';
import { removeFeedPosition } from '@/components/feed/restore-feed/use-restore-feed';

export const HomeMenuAction = () => {
  const isHome = Boolean(useMatch(paths.default));

  return (
    <MenuActionLink
      icon={<HomeIcon />}
      onClick={() => {
        if (isHome) {
          document.scrollingElement?.scrollTo(0, 0);
          removeFeedPosition(paths.default);
        }
      }}
      to={paths.default}
      label={'Home'}
    />
  );
};
