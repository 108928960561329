import { Link } from '@/routes-utils/navigation.tsx';
import { paths } from '@/routes-utils/paths.ts';
import { Button } from '@synoptic/ui-kit/button/button.tsx';
import { useIsTablet } from '@synoptic/ui-kit/responsive/hooks.js';
import { FC } from 'react';
import { HeaderLogo } from './header-logo.tsx';
import {
  publicSidebarButtonsContainer,
  publicSidebarContent,
  publicSidebarContentText,
  publicSidebarHeading,
  publicSidebarWrapper,
} from './public-sidebar.css.ts';
import { getRedirectLink } from '@/routes-utils/get-redirect-link.ts';
import { useLocation } from 'react-router';

export const PublicSidebar: FC = () => {
  const isTablet = useIsTablet();
  const location = useLocation();

  return (
    <div className={publicSidebarWrapper}>
      {isTablet ? <HeaderLogo /> : null}
      <div className={publicSidebarContent}>
        <span className={publicSidebarHeading}>Welcome to Synoptic</span>
        <span className={publicSidebarContentText}>
          Log in or create an account to see the latest crypto news and discover
          other feeds.
        </span>
        <div className={publicSidebarButtonsContainer}>
          <Button size={'large'} inversed asChild>
            <Link to={getRedirectLink(paths.signup, location.pathname)}>
              Sign up
            </Link>
          </Button>
          <Button size={'large'} asChild>
            <Link to={getRedirectLink(paths.login, location.pathname)}>
              Log in
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
};
