import { useReadQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useAppLoader } from './loader';

export const NotificationFaviconUpdater = () => {
  const { unreadNotificationsQueryRef } = useAppLoader();
  const {
    data: {
      notifications: { items: notificationItems },
    },
  } = useReadQuery(unreadNotificationsQueryRef);

  const svgIcon = document.getElementById('svgIcon') as HTMLLinkElement;

  const [originalSvgIcon] = useState<string>(svgIcon.href);
  const [originalTitle] = useState<string>(document.title);

  const createBadgeIcon = (
    iconHref: string,
    callback: (newHref: string) => void,
  ) => {
    fetch(iconHref)
      .then((response) => response.text())
      .then((svgString) => {
        // Create an SVG with a badge
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
        const svgRoot = svgDoc.documentElement;

        const badge = svgDoc.createElementNS(
          'http://www.w3.org/2000/svg',
          'circle',
        );
        badge.setAttribute('cx', '80');
        badge.setAttribute('cy', '20');
        badge.setAttribute('r', '15');
        badge.setAttribute('fill', '#CA0019');
        svgRoot.appendChild(badge);

        const serializer = new XMLSerializer();
        const newSvgString = serializer.serializeToString(svgRoot);
        const dataUrl = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(newSvgString)}`;

        callback(dataUrl);
      });
  };

  useEffect(() => {
    const updateFavicons = (count: number) => {
      if (count === 0) {
        if (svgIcon && originalSvgIcon) svgIcon.href = originalSvgIcon;
        document.title = originalTitle;
      } else {
        if (svgIcon)
          createBadgeIcon(svgIcon.href, (newHref) => (svgIcon.href = newHref));
        document.title = `(${count < 10 ? count : '9+'}) ${originalTitle}`;
      }
    };

    updateFavicons(notificationItems.length);
  }, [notificationItems, originalSvgIcon, originalTitle, svgIcon]);

  return null;
};
