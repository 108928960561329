import { useLocation } from 'react-router';
import { useEffect } from 'react';
import { traceError } from '../../utils/trace-error';
import { hasNewAppVersion, setHasNewAppVersion } from './has-new-app-version';

const pollInterval = 1000 * 60 * 5;

declare global {
  interface Window {
    __reactRouterManifest: { version: string };
  }
}

export const AppVersionUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    let prevVersion = window.__reactRouterManifest.version;
    let timeout: number | null = null;
    let ignore = false;

    const check = () => {
      timeout = null;

      fetch('/api/version')
        .then((res) => res.json())
        .then(({ version }) => {
          if (ignore) return;
          if (version === 'dev') return;

          if (prevVersion && prevVersion !== version) {
            setHasNewAppVersion(true);
            return;
          }

          prevVersion = version;

          timeout = window.setTimeout(check, pollInterval);
        })
        .catch((err) => {
          if (ignore) return;
          traceError(new Error('Failed to get app version', { cause: err }));
          timeout = window.setTimeout(check, pollInterval);
        });
    };

    timeout = window.setTimeout(check, pollInterval);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      ignore = true;
    };
  }, []);

  useEffect(() => {
    // for navigation caused by something else than Link or NavLink components, e.g. `useNavigate`
    if (hasNewAppVersion()) {
      window.location.reload();
    }
  }, [location]);

  return null;
};
