import { AppVersionUpdater } from '@/components/app-version-updater/app-version-updater';
import { Onboarding } from '@/components/onboarding/onboarding';
import { usePullToRefreshIos } from '@/utils/use-pull-to-refresh-ios.tsx';
import { useReadPushNotifications } from '@/utils/use-read-push-notifications.ts';
import { useIsLaptop } from '@synoptic/ui-kit/responsive/hooks.js';
import clsx from 'clsx';
import { Suspense } from 'react';
import { useLocation } from 'react-router';
import { NotificationsSubscription } from '../_app.p.notifications/notifications-subscription';
import { BootIntercom } from './intercom';
import { root, rootVariants, rootWithSidebarCollapsed } from './layout.css';
import { useAppLoader } from './loader';
import { MainContent } from './main-content/main-content';
import { NotificationFaviconUpdater } from './notification-favicon-updater';
import { PublicDrawer } from './sidebar/public-drawer';
import { PublicSidebar } from './sidebar/public-sidebar';
import { Sidebar } from './sidebar/sidebar';
import { useSidebarContext } from './sidebar/sidebar-context';

const AuthenticatedContent = () => {
  const loc = useLocation();

  usePullToRefreshIos();
  useReadPushNotifications();

  return (
    <>
      <Sidebar />
      <MainContent />
      <Suspense fallback={null} key={loc.key}>
        <Onboarding />
      </Suspense>
      <NotificationsSubscription />
      <Suspense fallback={null}>
        <NotificationFaviconUpdater />
      </Suspense>
      <Suspense fallback={null}>
        <BootIntercom />
      </Suspense>
    </>
  );
};

export const AppContent = () => {
  const isLaptop = useIsLaptop();
  const { authenticated } = useAppLoader();
  const { isCollapsed } = useSidebarContext();

  return (
    <div
      className={clsx(
        root,
        {
          [rootWithSidebarCollapsed]: isCollapsed,
        },
        rootVariants[authenticated ? 'protected' : 'public'],
      )}
    >
      {authenticated ? (
        <AuthenticatedContent />
      ) : (
        <>
          {isLaptop ? <PublicSidebar /> : <PublicDrawer />}
          <MainContent />
        </>
      )}
      <AppVersionUpdater />
    </div>
  );
};
