import { Outlet } from 'react-router';
import { FC, PropsWithChildren, Suspense } from 'react';
import * as mainContentStyles from './main-content.css.ts';

const MainContentWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      data-testid={'main-content-wrapper'}
      className={mainContentStyles.mainContentWrapper}
    >
      {children}
    </div>
  );
};

export const MainContent: FC = () => {
  return (
    <MainContentWrapper>
      <Suspense fallback={null}>
        <Outlet />
      </Suspense>
    </MainContentWrapper>
  );
};
