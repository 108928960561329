import { NavLink, NavLinkProps } from '@/routes-utils/navigation.tsx';
import {
  StreamsState,
  streamsState,
} from '@/routes/_app.p.streams/streams-state.ts';
import { useReactiveVar } from '@apollo/client';
import clsx from 'clsx';
import { forwardRef, ReactNode } from 'react';
import * as menuActionStyles from './menu-action.css.ts';
import {
  menuItemEmptyBadge,
  menuItemLink,
  menuItemText,
} from './menu-action.css.ts';

export const StreamsMenuAction = forwardRef<
  HTMLAnchorElement,
  {
    icon: ReactNode;
    label: string;
  } & NavLinkProps
>(function StreamsMenuAction({ icon, label, to, className, ...props }, ref) {
  const state = useReactiveVar(streamsState);

  return (
    <NavLink
      ref={ref}
      to={to}
      className={clsx(menuItemLink, className)}
      prefetch="render"
      {...props}
    >
      <div className={menuActionStyles.menuItemIcon}>
        {icon}
        {state === StreamsState.unvisited ? (
          <span className={menuItemEmptyBadge} />
        ) : null}
      </div>
      <span className={menuItemText}>{label}</span>
    </NavLink>
  );
});
