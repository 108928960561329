import { Intercom } from '@intercom/messenger-js-sdk';
import { useMe } from './use-me';

export const BootIntercom = () => {
  const me = useMe();

  Intercom({
    app_id: import.meta.env.VITE_INTERCOM_APP_ID,
    user_id: me.id,
    name: me.username,
    email: me.protectedFields?.email,
    created_at: new Date(me.createdAt).getTime() / 1000,
    user_hash: me.protectedFields?.intercomHmac,
    hide_default_launcher: true,
  });

  return null;
};
