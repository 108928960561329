import { Link } from '@/routes-utils/navigation.tsx';
import { paths } from '@/routes-utils/paths.ts';
import { useLocation } from 'react-router';
import { Button } from '@synoptic/ui-kit/button/button.tsx';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@synoptic/ui-kit/drawer/drawer.tsx';
import { useEffect, useState } from 'react';
import {
  publicDrawerContent,
  publicDrawerContentInnerContainer,
} from './public-drawer.css.ts';
import { getRedirectLink } from '@/routes-utils/get-redirect-link.ts';

export const PublicDrawer = () => {
  const drawerHeight = '294px';
  const hiddenDrawerHeight = '36px';
  const [snap, setSnap] = useState<number | string | null>(drawerHeight);
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 10000);
  }, []);

  return (
    <Drawer
      modal={false}
      open={open}
      snapPoints={[hiddenDrawerHeight, drawerHeight]}
      activeSnapPoint={snap}
      setActiveSnapPoint={setSnap}
      dismissible={false}
    >
      <DrawerContent className={publicDrawerContent} inversed>
        <div className={publicDrawerContentInnerContainer}>
          <DrawerHeader>
            <DrawerTitle>Welcome to Synoptic</DrawerTitle>
            <DrawerDescription>
              Log in or create an account to see the latest crypto news and
              discover other feeds.
            </DrawerDescription>
          </DrawerHeader>
          <DrawerFooter>
            <Button size={'large'} inversed asChild>
              <Link to={getRedirectLink(paths.signup, location.pathname)}>
                Sign up
              </Link>
            </Button>
            <Button size={'large'} asChild>
              <Link to={getRedirectLink(paths.login, location.pathname)}>
                Log in
              </Link>
            </Button>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
