import { useIsLaptop } from '@synoptic/ui-kit/responsive/hooks.js';
import { ReactNode, FC, useState, useMemo } from 'react';
import { getPersistedState, persistState } from '@/state-utils/persisted-state';
import { SidebarContextProvider } from './sidebar-context';

enum SidebarState {
  Expanded = 'expanded',
  Collapsed = 'collapsed',
}

export const SidebarProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const initialState = getPersistedState(
      'sidebar-state',
      SidebarState,
      SidebarState.Expanded,
    );
    return initialState === SidebarState.Collapsed;
  });
  const isLaptop = useIsLaptop();

  const contextValue = useMemo(
    () => ({
      isCollapsed: isCollapsed && isLaptop,
      setIsCollapsed: (value: boolean | ((prev: boolean) => boolean)) => {
        setIsCollapsed(value);
        const newValue =
          typeof value === 'function' ? value(isCollapsed) : value;
        persistState(
          'sidebar-state',
          newValue ? SidebarState.Collapsed : SidebarState.Expanded,
        );
      },
    }),
    [isCollapsed, isLaptop, setIsCollapsed],
  );

  return (
    <SidebarContextProvider value={contextValue}>
      {children}
    </SidebarContextProvider>
  );
};
