import { useReadNotification } from '@/routes/_app.p.notifications/use-read-notification.ts';
import { useEffect } from 'react';
import { traceError } from '@/utils/trace-error.ts';
import { isServiceWorkerAvailable } from '@/utils/is-service-worker-available.ts';

export const useReadPushNotifications = () => {
  const [readNotification] = useReadNotification();

  useEffect(() => {
    if (isServiceWorkerAvailable()) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data?.type === 'READ_NOTIFICATION') {
          const notificationId = event.data.notificationId;

          readNotification(notificationId).catch((error) => {
            traceError(
              new Error('Error marking notification as read', {
                cause: error,
              }),
            );
          });
        }
      });
    }
  }, [readNotification]);
};
