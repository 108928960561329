import { NewPostModal } from '@/components/new-post-modal/modal.tsx';
import { Button } from '@synoptic/ui-kit/button/button.tsx';
import { IconButton } from '@synoptic/ui-kit/button/icon-button.tsx';
import { CreateIcon } from '@synoptic/ui-kit/icons/react/create.tsx';
import { useIsLaptop, useIsTablet } from '@synoptic/ui-kit/responsive/hooks.ts';
import { useThemeMode } from '@synoptic/ui-kit/theme-context.js';
import { useSidebarContext } from '../../sidebar-context.tsx';
import {
  createPostButton,
  createPostButtonIcon,
  menuItemButton,
} from './menu-action.css.ts';

export const CreatePostMenuAction = () => {
  const isLaptop = useIsLaptop();
  const isTablet = useIsTablet();
  const isLightMode = useThemeMode() === 'light';

  const { isCollapsed } = useSidebarContext();

  return (
    <NewPostModal
      trigger={
        (isLaptop || !isTablet) && !isCollapsed ? (
          <Button
            aria-label="Create post"
            startIcon={!isLaptop && <CreateIcon />}
            variant={isLaptop ? 'primary' : 'ghost'}
            className={isLaptop ? createPostButton : menuItemButton}
            inversed={isLaptop && isLightMode}
          >
            {isLaptop ? 'Create post' : 'Create'}
          </Button>
        ) : (
          <IconButton
            aria-label="Create post"
            className={createPostButtonIcon}
            inversed
          >
            <CreateIcon />
          </IconButton>
        )
      }
    />
  );
};
