import { showMessages } from '@intercom/messenger-js-sdk';
import { Button } from '@synoptic/ui-kit/button/button.js';
import { WarningTriangleIcon } from '@synoptic/ui-kit/icons/react/warning-triangle.js';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { menuItemButton, menuItemText } from './menu-action.css';

export const ReportMenuAction = forwardRef<
  HTMLButtonElement,
  { className?: string }
>(function ReportMenuAction({ className }, ref) {
  return (
    <Button
      ref={ref}
      className={clsx(menuItemButton, className)}
      startIcon={<WarningTriangleIcon />}
      variant="ghost"
      onClick={() => {
        showMessages();
      }}
    >
      <span className={menuItemText}>Contact us</span>
    </Button>
  );
});
